import React, { useState, useEffect } from 'react';
import { AiFillQqCircle } from 'react-icons/ai';
import { RiBilibiliLine } from 'react-icons/ri';
import { Helmet } from 'react-helmet';
import './App.css';

const scrollToSection = (sectionId) => {
  const element = document.getElementById(sectionId);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

const Navbar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleContactClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleNavLinkClick = (sectionId) => {
    scrollToSection(sectionId);
    document.title = `Nostalgia Studio | ${sectionId}`;
  };

  return (
    <nav className="navbar">
      <div className="nav-logo">
        <img src="https://www.092333.xyz/favicon.ico" alt="Logo" style={{ width: '48px', height: '48px', marginRight: '8px' }} />
        Nostalgia Studio
      </div>
      <div className="nav-links">
        <ul>
          <li><a href="#home" className="nav-link" onClick={() => handleNavLinkClick('home')}>首页</a></li>
          <li><a href="#projects" className="nav-link" onClick={() => handleNavLinkClick('projects')}>项目</a></li>
          <li><a href="#team" className="nav-link" onClick={() => handleNavLinkClick('team')}>团队</a></li>
          <button onClick={handleContactClick} className="contact-button">联系我们</button>
        </ul>
      </div>
      {isModalOpen && (
        <div className="contact-modal">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">联系我们</h2>
              <button onClick={closeModal} className="close-button">&times;</button>
            </div>
            <div className="modal-body">
              <p>欢迎通过以下方式联系我们：</p>
              <div className="contact-info">
                <a
                  href="https://qm.qq.com/cgi-bin/qm/qr?k=uCRSo3CgEh2QG9YtzokCpMF0szA8UabE" 
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <AiFillQqCircle
                    style={{
                      width: '24px',
                      height: '24px',
                      marginRight: '8px',
                      color: '#007bff',
                    }}
                  />
                  加入我们的QQ群
                </a>
                <a
                  href="https://space.bilibili.com/539318164" 
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <RiBilibiliLine
                    style={{
                      width: '24px',
                      height: '24px',
                      marginRight: '8px',
                      color: '#ff69b4',
                    }}
                  />
                  访问我们的哔哩哔哩频道
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

const ProjectCard = ({ title, description, image, url }) => {
  const imagePath = `assets/images/${image}`;
  
  return (
    <div className="project-card">
      <img src={imagePath} alt={title} className="project-image" />
      <h3>{title}</h3>
      <p>{description}</p>
      <a href={url} target="_blank" rel="noopener noreferrer">查看项目</a>
    </div>
  );
};

const Projects = ({ projects }) => {
  return (
    <section className="projects" id="projects">
      <h2>我们的项目</h2>
      <div className="project-list">
        {projects.map((project) => (
          <ProjectCard key={project.id} {...project} />
        ))}
      </div>
    </section>
  );
};

const TeamMember = ({ name, role, image, bio }) => {
  const imagePath = `assets/images/${image}`;
  
  return (
    <div className="team-member">
      <img src={imagePath} alt={name} className="team-image" />
      <h3>{name}</h3>
      <p>{role}</p>
      <p>{bio}</p>
    </div>
  );
};

const Team = () => {
  const teamMembersData = [
    {
      id: 1,
      name: 'Alice',
      role: 'Founder',
      image: 'alice.jpg',
      bio: 'Alice是Nostalgia Studio的创始人，负责整体战略和团队管理。她在软件开发和项目管理方面有着丰富的经验。',
    },
    {
      id: 2,
      name: 'Bob',
      role: 'Developer',
      image: 'bob.jpg',
      bio: 'Bob是我们的资深开发者，专注于Minecraft相关软件的开发。他对技术充满热情，致力于为用户提供最佳的游戏体验。',
    },
  ];

  return (
    <section className="team" id="team">
      <h2>我们的团队</h2>
      <div className="team-members">
        {teamMembersData.map((member) => (
          <TeamMember key={member.id} {...member} />
        ))}
      </div>
    </section>
  );
};

const Footer = () => {
  return (
    <footer className="footer">
      <p>&copy; 2024 Nostalgia Studio. All rights reserved.</p>
    </footer>
  );
};

const App = () => {
  useEffect(() => {
    const handleHashChange = () => {
      const currentHash = window.location.hash;
      if (currentHash) {
        document.title = `Nostalgia Studio | ${currentHash.slice(1)}`;
      }
    };

    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  const projectsData = [
    {
      id: 1,
      title: 'Nostalgia Minecraft Launcher',
      description: '这是一个Minecraft的启动器，支持Java全版本游戏下载，支持Mod的安装，和下载整合包（制作中）',
      image: 'nmcl.png',
      url: 'https://nmcl.092333.xyz',
    },
    {
      id: 2,
      title: 'PVZ杂交工具箱',
      description: '这是一个植物大战僵尸杂交版的工具箱，可以提供杂交版的辅助功能，正在制作中...',
      image: 'project2.jpg',
      url: 'https://project2.com',
    },
    {
      id: 3,
      title: '项目3',
      description: '项目3的简介。',
      image: 'project3.jpg',
      url: 'https://project3.com',
    },
    // 更多项目...
  ];

  return (
    <div className="App">
      <Navbar />
      <Helmet>
        <title>Nostalgia Studio | 首页</title>
        <meta name="description" content="Nostalgia Studio的官方网站，提供创新的软件开发服务。" />
      </Helmet>
      <main>
        <section id="home">
          <h2>欢迎来到Nostalgia Studio</h2>
          <p>这里是首页的介绍内容。</p>
        </section>
        <Projects projects={projectsData} />
        <Team />
      </main>
      <Footer />
    </div>
  );
};

export default App;